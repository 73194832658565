import { Status } from '@/constants'
import type { Product, Promotion } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import { useMemo } from 'react'
import ProductCard from '../ProductCard'

interface ProductTrendingProp {
  dataProductTrend: Promotion
}

const ProductTrending = ({ dataProductTrend }: ProductTrendingProp) => {
  const name = useMemo(() => {
    return getTranslate(dataProductTrend?.name)
  }, [dataProductTrend])
  return (
    <>
      {dataProductTrend && dataProductTrend?.status == Status.ACTIVED && (
        <section className='mb-[80px]'>
          <div className='mb-[40px] text-center '>
            <h2 className='text-center text-HeadlineLarge font-bold text-dark-400'>
              {name}
            </h2>
            {dataProductTrend?.description ? (
              <p className='text-sm text-dark-300'>
                {getTranslate(dataProductTrend?.description)}
              </p>
            ) : (
              ''
            )}
          </div>
          <div className='grid grid-cols-4 gap-5'>
            {dataProductTrend?.products.slice(0, 4).map((product: Product) => (
              <ProductCard
                key={product._id}
                product={product}
                priority={true}
                layout={4}
                type='trending'
              />
            ))}
          </div>
        </section>
      )}
    </>
  )
}

export default ProductTrending
