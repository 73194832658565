/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNotification } from '@/containers'
import type { Product } from '@/types'
import { type FlashSale as FlashSaleType } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Link from 'next/link'
import { useRef, type FC } from 'react'
import { Autoplay, FreeMode, Grid, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import ProductCard from '../ProductCard'
interface Props {
  flashSale: FlashSaleType | null
}

export const FlashSale: FC<Props> = ({ flashSale }) => {
  const { systemConfig } = useNotification()
  const qtt =
    systemConfig?.imageQlt && systemConfig?.imageQlt != ''
      ? systemConfig?.imageQlt
      : 80

  const customStyle = {
    backgroundColor: flashSale?.customization?.backgroundColor,
    backgroundImage:
      flashSale?.customization?.blockBackgroundImage &&
      `url(${flashSale?.customization?.blockBackgroundImage}?format=webp&quality=${qtt})`,
    backgroundRepeat:
      flashSale?.customization?.blockBackgroundImage && 'no-repeat',
    backgroundPosition:
      flashSale?.customization?.blockBackgroundImage && 'center center',
    backgroundSize:
      flashSale?.customization?.blockBackgroundImage && '100% 100%',
  }
  const arrFlashSale: Product[] =
    flashSale?.products?.slice(0, 24)?.map((e, i) => {
      return { ...e, index: i + 1 }
    }) || []
  function mergeArrays(arr: Product[], numSubArrays: number) {
    const subArrays: Product[][] = Array.from(
      { length: numSubArrays },
      () => []
    )
    for (let i = 0; i < arr.length; i++) {
      subArrays[i % numSubArrays].push(arr[i])
    }
    const result: Product[] = []
    for (let i = 0; i < subArrays.length; i++) {
      for (let j = 0; j < subArrays[i].length; j++) {
        result.push(subArrays[i][j])
      }
    }
    return result
  }
  const numSubArrays = 3
  const sortFlashSale = mergeArrays(arrFlashSale, numSubArrays)
  const navigationNextRef = useRef(null)
  const navigationPrevRef = useRef(null)
  const swiper = useRef(null)
  return (
    <section>
      {flashSale && flashSale.products.length > 0 ? (
        <div
          className={`${
            flashSale
              ? 'relative mb-8 rounded-[8px] px-[20px] pb-[20px] pt-2'
              : ''
          } `}
          style={customStyle}
        >
          <div className='flash-sale--header relative mb-2 min-h-[60px]'>
            {!flashSale?.customization?.blockBackgroundImage ? (
              <>
                <h2
                  style={{
                    color: flashSale?.customization?.titleColor,
                  }}
                  className='relative w-full text-HeadlineLarge font-bold text-dark-400'
                >
                  {getTranslate(flashSale?.name)}
                </h2>
                <p
                  style={{
                    color: flashSale?.customization?.descriptionColor,
                    backgroundColor: flashSale.blockLinkBackgroundColor,
                  }}
                  className='sortDescription h-[20px]  text-mainBody text-gray-500'
                >
                  {getTranslate(flashSale?.description)}
                </p>
              </>
            ) : (
              ''
            )}
            <Link
              href={'/flash-sale'}
              className='btnMore absolute right-0 top-2 flex h-[36px] items-center justify-center gap-1 border-[0.5px] border-grey-900 bg-white px-2 text-mainBody'
              style={{
                backgroundColor: flashSale?.customization?.viewMoreButtonColor,
                color: flashSale?.customization?.viewMoreColor,
                borderColor: flashSale?.customization?.viewMoreButtonColor,
              }}
            >
              <span className='uppercase'>Tất cả</span>
              <img
                src='/assets/icons/arrow/arrow-right-long.svg'
                alt='Xem thêm'
              />
            </Link>
          </div>
          <div className='flash-sale--content'>
            {flashSale?.products?.length > 8 ? (
              <>
                <Swiper
                  ref={swiper}
                  modules={[Grid, FreeMode, Autoplay, Navigation]}
                  className='flashSaleSwp max-w-screen'
                  slidesPerView={4}
                  slidesPerGroup={4}
                  navigation={{
                    nextEl: '.swpBtnNext',
                    prevEl: '.swpBtnPrev',
                  }}
                  freeMode={true}
                  // loop={true}
                  spaceBetween={20}
                  grid={{
                    rows: 3,
                    fill: 'row',
                  }}
                  autoplay={{
                    delay: 15000,
                    disableOnInteraction: false,
                    stopOnLastSlide: true,
                  }}
                  speed={400}
                >
                  {sortFlashSale?.map((product: Product, idx: number) => (
                    <SwiperSlide key={product._id + idx + 'fs'}>
                      <ProductCard
                        product={product}
                        type='flashSale'
                        layout={4}
                        className='productCardSwp'
                        descClassName={'px-[12px] pb-3'}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div
                  ref={navigationPrevRef}
                  className='swpNav swpBtnPrev stroke-primary-dark absolute left-0 top-[53%] z-30 m-0 flex translate-y-[-50%] cursor-pointer items-center'
                >
                  <img
                    src='/assets/icons/arrow/slider-button.svg'
                    alt='Next'
                    className='h-[40px] w-[40px]'
                  />
                </div>
                <div
                  ref={navigationNextRef}
                  className='swpNav swpBtnNext stroke-primary-dark absolute right-0 top-[53%] z-30 m-0 flex translate-y-[-50%] cursor-pointer items-center'
                >
                  <img
                    src='/assets/icons/arrow/slider-button.svg'
                    alt='Next'
                    className='h-[40px] w-[40px] rotate-180'
                  />
                </div>
              </>
            ) : (
              <div className='products mt-[30px] grid grid-cols-4 gap-[20px]'>
                {sortFlashSale?.map((product: Product) => (
                  <ProductCard key={product._id} product={product} layout={4} />
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </section>
  )
}
