import type { BannerItem as HomeSliderType } from '@/types'
import Link from 'next/link'
import { useRef, useState, type FC } from 'react'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'

interface Props {
  sliders: Array<HomeSliderType>
}

export const HomeSlider: FC<Props> = ({ sliders }: Props) => {
  const [activeSlide, setActiveSlide] = useState(1)
  const totalNum = sliders?.length
  const [isAutoPlaying, setIsAutoPlaying] = useState<boolean>(true)
  const sliderSettings: Settings = {
    autoplay: isAutoPlaying,
    // dots: false,
    autoplaySpeed: 3000,
    speed: 800,
    infinite: true,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: '25%',
    slidesToShow: 1,
    afterChange: (current: number) => setActiveSlide(current + 1),
  }
  const ref = useRef<Slider>(null)
  const handlePlayStopClick = () => {
    if (ref.current) {
      ref.current.slickPlay()
      setIsAutoPlaying(!isAutoPlaying)
    }
  }
  return (
    <section className={`${!sliders && 'hidden'} homeSlider mb-4`}>
      {sliders ? (
        <Slider
          ref={ref}
          {...sliderSettings}
          className='max-w-screen flex w-full'
        >
          {sliders &&
            sliders.map((slider: HomeSliderType, index: number) => (
              <Link
                href={slider?.link || '/'}
                key={index}
                className={`block w-full max-w-[60vw] px-[4px] outline-none`}
              >
                <ImageOptimize
                  className={`h-auto w-full overflow-hidden rounded-xl object-contain`}
                  src={slider?.image}
                  alt={slider?.title}
                  width={950}
                  height={470}
                  priority
                />
                <h3 className='text-xl font-bold leading-loose text-dark-900'>
                  {slider?.title}
                </h3>
                <div className='text-sm font-normal leading-tight text-gray-500'>
                  {slider?.description}
                </div>
              </Link>
            ))}
        </Slider>
      ) : (
        ''
      )}
      {sliders ? (
        <div className='mt-3 flex items-center justify-center gap-x-1'>
          <div
            className='pointer-events-auto w-[40px] cursor-pointer rounded bg-gray-100 p-[11px]'
            onClick={() => {
              ref.current?.slickPrev()
            }}
          >
            <img src='/assets/icons/arrow/ArrowPrev.svg' alt='Prev' />
          </div>
          <span className='h-[40px] w-[58px] justify-center text-center leading-[40px]'>
            <span className='text-base text-dark-900'>{activeSlide}</span>
            <span className='text-base text-dark-300'>/{totalNum}</span>
          </span>
          <div
            className='pointer-events-auto w-[40px] cursor-pointer rounded bg-gray-100 p-[11px]'
            onClick={() => {
              ref.current?.slickNext()
            }}
          >
            <img
              src='/assets/icons/arrow/ArrowPrev.svg'
              alt='Next'
              className='rotate-180'
            />
          </div>
          <div
            onClick={handlePlayStopClick}
            className='pointer-events-auto flex w-[40px] cursor-pointer items-center justify-center rounded bg-gray-100 p-[11px]'
          >
            {isAutoPlaying ? (
              <img
                src='/assets/icons/arrow/pause.svg'
                alt='Pause'
                className='rotate-180'
              />
            ) : (
              <img src='/assets/icons/arrow/play.svg' alt='Play' />
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </section>
  )
}
