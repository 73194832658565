import { type Product, type ProductResponse, type Promotion } from '@/types'
import { useMemo, useState } from 'react'

const DEFAULT_CATE = 0
export const usePromotion = (promotions: Promotion[]) => {
  const [categoryActive, setCategoryActive] = useState<Promotion | null>(null)

  useMemo(() => {
    if (promotions) {
      const activePromo =
        promotions.find(e => e.order === DEFAULT_CATE) || promotions[0]
      if (activePromo) {
        setCategoryActive(activePromo)
      }
    }
  }, [promotions])

  const products = useMemo(() => {
    if (categoryActive) {
      return categoryActive?.products
    }
    return []
  }, [categoryActive])

  const transformData = (data: ProductResponse) => {
    const products = data.data.map((item: Product) => {
      return item
    })
    return products
  }

  return {
    categoryActive,
    setCategoryActive,
    products,
    transformData,
  }
}
